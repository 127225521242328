import { HeadingL, LayoutContainer, Link, Paper, PaperBody, PaperHeader, Space, VerticalFlex } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';
import { TermsOfUseList } from './TermsOfUse.style';

const TermsOfUse: FC = () => {
  const t = useTranslate();

  return (
    <LayoutContainer>
      <Paper>
        <PaperHeader>
          <HeadingL>
            {t('use.title')}
          </HeadingL>
        </PaperHeader>

        <PaperBody>
          <VerticalFlex $gap={Space.V40}>
            <VerticalFlex $gap={Space.V8}>
              <div>{t('use.firstSection.0')}</div>
              <div>{t('use.firstSection.1')}</div>
              <div>{t('use.firstSection.2')}</div>
            </VerticalFlex>

            <TermsOfUseList>
              <li>
                <VerticalFlex $gap={Space.V16}>
                  {t('use.purpose.title')}

                  <TermsOfUseList>
                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.purpose.list.0.name')}</div>
                        <div>{t('use.purpose.list.0.texts.0')}</div>
                        <div>{t('use.purpose.list.0.texts.1')}</div>
                      </VerticalFlex>
                    </li>
                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.purpose.list.1.name')}</div>
                        <div>{t('use.purpose.list.1.texts.0')}</div>
                      </VerticalFlex>
                    </li>
                  </TermsOfUseList>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V8}>
                  <div>{t('use.eligibility.title')}</div>
                  <div>{t('use.eligibility.text')}</div>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V8}>
                  <div>{t('use.acceptance.title')}</div>
                  <div>{t('use.acceptance.text')}</div>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V16}>
                  {t('use.compliant.title')}

                  <TermsOfUseList>
                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.compliant.list.0.name')}</div>
                        <div>{t('use.compliant.list.0.texts.0')}</div>
                      </VerticalFlex>
                    </li>

                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.compliant.list.1.name')}</div>
                        <div>{t('use.compliant.list.1.texts.0')}</div>

                        <TermsOfUseList>
                          <li>{t('use.compliant.list.1.list.0')}</li>
                          <li>{t('use.compliant.list.1.list.1')}</li>
                          <li>{t('use.compliant.list.1.list.2')}</li>
                          <li>{t('use.compliant.list.1.list.3')}</li>
                          <li>{t('use.compliant.list.1.list.4')}</li>
                          <li>{t('use.compliant.list.1.list.5')}</li>
                          <li>{t('use.compliant.list.1.list.6')}</li>
                          <li>{t('use.compliant.list.1.list.7')}</li>
                          <li>{t('use.compliant.list.1.list.8')}</li>
                          <li>{t('use.compliant.list.1.list.9')}</li>
                          <li>{t('use.compliant.list.1.list.10')}</li>
                          <li>{t('use.compliant.list.1.list.11')}</li>
                          <li>{t('use.compliant.list.1.list.12')}</li>
                          <li>{t('use.compliant.list.1.list.13')}</li>
                          <li>{t('use.compliant.list.1.list.14')}</li>
                          <li>{t('use.compliant.list.1.list.15')}</li>
                        </TermsOfUseList>
                      </VerticalFlex>
                    </li>

                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.compliant.list.2.name')}</div>
                        <div>{t('use.compliant.list.2.texts.0')}</div>

                        <TermsOfUseList>
                          <li>{t('use.compliant.list.2.list.0')}</li>
                          <li>{t('use.compliant.list.2.list.1')}</li>
                          <li>{t('use.compliant.list.2.list.2')}</li>
                          <li>{t('use.compliant.list.2.list.3')}</li>
                          <li>{t('use.compliant.list.2.list.4')}</li>
                          <li>{t('use.compliant.list.2.list.5')}</li>
                          <li>{t('use.compliant.list.2.list.6')}</li>
                          <li>{t('use.compliant.list.2.list.7')}</li>
                          <li>{t('use.compliant.list.2.list.8')}</li>
                          <li>{t('use.compliant.list.2.list.9')}</li>
                          <li>{t('use.compliant.list.2.list.10')}</li>
                          <li>{t('use.compliant.list.2.list.11')}</li>
                          <li>{t('use.compliant.list.2.list.12')}</li>
                          <li>{t('use.compliant.list.2.list.13')}</li>
                          <li>{t('use.compliant.list.2.list.14')}</li>
                          <li>{t('use.compliant.list.2.list.15')}</li>
                        </TermsOfUseList>
                      </VerticalFlex>
                    </li>

                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.compliant.list.3.name')}</div>
                        <div>{t('use.compliant.list.3.texts.0')}</div>
                      </VerticalFlex>
                    </li>
                  </TermsOfUseList>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V16}>
                  <div>{t('use.registration.title')}</div>

                  <TermsOfUseList>
                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.registration.list.0.name')}</div>
                        <div>{t('use.registration.list.0.texts.0')}</div>
                      </VerticalFlex>
                    </li>

                    <li>
                      <VerticalFlex $gap={Space.V8}>
                        <div>{t('use.registration.list.1.name')}</div>
                        <div>{t('use.registration.list.1.texts.0')}</div>
                      </VerticalFlex>
                    </li>
                  </TermsOfUseList>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V8}>
                  <div>{t('use.policy.title')}</div>
                  <div>{t('use.policy.texts.0')}</div>

                  <Link
                    label="https://www.euronext.com/privacy-policy"
                    href="https://www.euronext.com/privacy-policy"
                    blank
                  />

                  <div>{t('use.policy.texts.1')}</div>
                </VerticalFlex>
              </li>

              <li>
                <VerticalFlex $gap={Space.V8}>
                  <div>{t('use.misuse.title')}</div>
                  <div>{t('use.misuse.text')}</div>
                </VerticalFlex>
              </li>
            </TermsOfUseList>
          </VerticalFlex>
        </PaperBody>
      </Paper>
    </LayoutContainer>
  );
};
export default TermsOfUse;
