import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const LOAPage: FC = () => {
  const { loaUrl } = useAppSelector(selectEnv);

  const config = { Auth };

  return (
    <MicroFrontend
      appId={WidgetTrigram.LOA}
      host={loaUrl}
      filterPublicUrl
      name="LogicalAccess" // DEPRECATED: see MicroFrontend.tsx for more details
      Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
    />
  );
};

export default LOAPage;
