import { BorderRadius, Color, Space } from 'dap-ui-components';
import styled from 'styled-components';

export const WidgetLayout = styled.div`
  align-items: stretch;
  background: ${Color.Neutral.V30};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: ${BorderRadius.V8};
  color: ${Color.Teal.V30};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 252px;
  justify-content: space-between;
  padding: ${Space.V24};
  transition: all 0.1s;

  &:hover {
    background: ${Color.Neutral.V40};
  }
`;

export const BodySection = styled.div`
  min-height: 0px; // Fix body overflow
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
