import { API } from '@aws-amplify/api';
import { AxiosResponse } from 'axios';
import { getAccessToken } from 'helpers/authHelper';
import { AppGateway } from 'models';

// Base requests
export function sendGet<T>(
  gateway: AppGateway,
  endpoint: string,
  queryStringParameters?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<T> {
  return API.get(gateway, endpoint, { queryStringParameters, ...extraConfig });
}

export function sendPost<T>(
  gateway: AppGateway,
  endpoint: string,
  body?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<T> {
  return API.post(gateway, endpoint, { body, ...extraConfig });
}

export function sendPut<T>(
  gateway: AppGateway,
  endpoint: string,
  body?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<T> {
  return API.put(gateway, endpoint, { body, ...extraConfig });
}

export function sendPatch<T>(
  gateway: AppGateway,
  endpoint: string,
  body?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<T> {
  return API.patch(gateway, endpoint, { body, ...extraConfig });
}

export function sendDelete<T>(
  gateway: AppGateway,
  endpoint: string,
  body?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<T> {
  return API.del(gateway, endpoint, { body, ...extraConfig });
}

export function abortApi<T>(api: Promise<T> | null): void {
  API.cancel(api || Promise.resolve(), 'Operation aborted.');
}

// Download as Blob
export type BlobResponse = { blob: Blob, fileName?: string };

export async function sendGetForBlob(
  gateway: AppGateway,
  endpoint: string,
  queryStringParameters?: unknown,
  extraConfig?: Record<string, unknown>,
): Promise<BlobResponse> {
  const response = await sendGet<AxiosResponse>(
    gateway,
    endpoint,
    queryStringParameters,
    { ...extraConfig, response: true, responseType: 'blob' },
  );

  const blob = response.data;
  const disposition = response.headers['content-disposition'];
  const fileName = /^\s*attachment;.*filename="([^"]+)"/i.exec(disposition)?.[1];
  return { blob, fileName };
}

// define the function that should get the token header
export async function getTokenHeader(): Promise<Record<string, string>> {
  try {
    const token = await getAccessToken();
    return { Authorization: `Bearer ${token}` };
  } catch (error) {
    return {};
  }
}

// Setup the API Client to retrieve the token to send in the headers
export function setupAPI(uamApiUrl: string): void {
  API.configure({
    endpoints: [{
      name: AppGateway.UAM,
      region: 'eu-west-1',
      endpoint: uamApiUrl,
      custom_header: () => getTokenHeader(),
    }],
  });
}
