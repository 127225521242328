import SubWidget, { SubWidgetLinkModel } from 'components/SubWidget/SubWidget';
import { Button, HeadingM, LayoutContainer, ResponsiveGrid, Space, VerticalFlex } from 'dap-ui-components';
import { canShowWidget } from 'helpers/authHelper';
import { useTranslate } from 'i18n';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CorporateActions: FC = () => {
  const t = useTranslate();
  const { uamEnabledApps, showCetRegulatoryDocs } = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const CETLinks: SubWidgetLinkModel[] = [
    {
      title: t('dashboard.corporateActions.requestsList.all'),
      route: ROUTES.CEP,
    },
  ];

  if (showCetRegulatoryDocs) {
    CETLinks.push({
      title: t('dashboard.corporateActions.requestsList.regulatoryDocuments'),
      route: ROUTES.CET_REGULATORY_DOCS,
    });
  }

  return (
    <LayoutContainer>
      <VerticalFlex $gap={Space.V24}>
        <HeadingM>
          {t('dashboard.corporateActions.title')}
        </HeadingM>

        <ResponsiveGrid
          $m="repeat(2, 1fr)"
          $l="repeat(3, 1fr)"
          $xl="repeat(4, 1fr)"
        >
          {canShowWidget(WidgetTrigram.CET, user, uamEnabledApps) && (
            <SubWidget
              title={t(`apps.${WidgetTrigram.CET}`)}
              links={CETLinks}
              qa={'etp-card'}
              footer={<>
                <Button
                  qa='etp-new-request'
                  label={t('dashboard.corporateActions.requestsList.createNew')}
                  onClick={() => navigate(ROUTES.CEP_NEW_REQUEST)}
                />
              </>}
            />
          )}

          {canShowWidget(WidgetTrigram.CEQ, user, uamEnabledApps) && (
            <SubWidget
              title={t(`apps.${WidgetTrigram.CEQ}`)}
              links={[
                {
                  title: t('dashboard.corporateActions.requestsList.all'),
                  route: ROUTES.CEQ,
                },
              ]}
            />
          )}

          {canShowWidget(WidgetTrigram.CBO, user, uamEnabledApps) && (
            <SubWidget
              title={t(`apps.${WidgetTrigram.CBO}`)}
              links={[
                {
                  title: t('dashboard.corporateActions.requestsList.all'),
                  route: ROUTES.CBO,
                },
              ]}
              footer={<>
                <Button
                  label={t('dashboard.corporateActions.requestsList.createNew')}
                  onClick={() => navigate(ROUTES.CBO_NEW_REQUEST)}
                />
              </>}
            />
          )}

          {canShowWidget(WidgetTrigram.CAW, user, uamEnabledApps) && (
            <SubWidget
              title={t(`apps.${WidgetTrigram.CAW}`)}
              links={[
                {
                  title: t('dashboard.corporateActions.requestsList.all'),
                  route: ROUTES.CAW,
                },
              ]}
              footer={<>
                <Button
                  label={t('dashboard.corporateActions.requestsList.createNew')}
                  onClick={() => navigate(ROUTES.CAW_NEW_REQUEST)}
                />
              </>}
            />
          )}
        </ResponsiveGrid>
      </VerticalFlex>
    </LayoutContainer>
  );
};

export default CorporateActions;
