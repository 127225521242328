import { Auth } from '@aws-amplify/auth';
import SettingsModal from 'components/SettingsModal/SettingsModal';
import {
  AccountCircleIcon,
  ActionMenu,
  ArrowRightIcon,
  BoldTextM,
  Breakpoint,
  ChevronDownIcon,
  ChevronUpIcon,
  GearIcon,
  HamburgerIcon,
  HorizontalFlex,
  MediumTextM,
  PopoverSize,
  Space,
  TopBar,
  TopBarButton,
  TopBarEnvBar,
  VerticalSeparator,
  WhiteLogo,
} from 'dap-ui-components';
import { removeMockedTokens } from 'helpers/authHelper';
import { setMFAClientEnabled } from 'helpers/mfaHelper';
import { useTranslate } from 'i18n';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';
import packageJson from '../../../package.json';

const AppTopBar: FC = () => {
  const t = useTranslate();
  const { showEnvBar, envId, showSettings } = useAppSelector(selectEnv);
  const { firstName, lastName, email } = useAppSelector(selectUser);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const userName = firstName && lastName ? `${firstName} ${lastName}` : email;

  const routeIndex = Object.values(ROUTES)
    .findIndex(route => location.pathname.startsWith(route) && route !== '/');

  const appTrigram = Object.keys(ROUTES)[routeIndex]?.split('_')[0];
  const currentAppName = t(`apps.${appTrigram}`, '');

  const handleLogout = () => {
    removeMockedTokens();
    setMFAClientEnabled(false);
    Auth.signOut();
  };

  return (
    <TopBar>
      <HorizontalFlex
        $gap={Space.V16}
        $grow
      >
        <HorizontalFlex $gap={Space.V12}>
          <WhiteLogo />

          <VerticalSeparator />

          <HorizontalFlex
            $gapX={Space.V12}
            $breakpoint={Breakpoint.L}
          >
            <MediumTextM $ellipsis>
              {t('header.myEuronext')}
            </MediumTextM>

            <VerticalSeparator $breakpoint={Breakpoint.L} />

            {currentAppName && (
              <BoldTextM $ellipsis>
                {currentAppName}
              </BoldTextM>
            )}
          </HorizontalFlex>
        </HorizontalFlex>

        <TopBarButton
          leftIcon={HamburgerIcon}
          label={t('header.applications')}
          title={t('header.applications')}
          onClick={() => navigate(ROUTES.HOME)}
        />

        {showEnvBar && (
          <TopBarEnvBar $ellipsis>
            {t('header.env', undefined, { envId, version: packageJson.version })}
          </TopBarEnvBar>
        )}
      </HorizontalFlex>

      <HorizontalFlex
        $gap={Space.V8}
        $shrink
      >
        {showSettings && (
          <TopBarButton
            leftIcon={GearIcon}
            title={t('settings.title')}
            onClick={() => setSettingsModalOpen(true)}
          />
        )}

        {isSettingsModalOpen && (
          <SettingsModal onClose={() => setSettingsModalOpen(false)} />
        )}

        <ActionMenu
          options={[
            {
              label: t('header.account'),
              icon: AccountCircleIcon,
              onClick: () => {
                const detail = ROUTES.UAM_ACCOUNT;
                window.dispatchEvent(new CustomEvent('portal-navigate', { detail }));
              },
            },
            {
              label: t('header.logout'),
              icon: ArrowRightIcon,
              onClick: handleLogout,
            },
          ]}
          size={PopoverSize.XS}
          getTrigger={({ open, updateOpen, onBlur }) => (
            <TopBarButton
              leftIcon={AccountCircleIcon}
              rightIcon={open ? ChevronUpIcon : ChevronDownIcon}
              label={userName}
              title={t('header.userMenu')}
              onClick={() => updateOpen(!open)}
              onBlur={onBlur}
            />
          )}
        />
      </HorizontalFlex>
    </TopBar>
  );
};

export default AppTopBar;
