/*
 * WARNING: these enum values are agreed with the UAM to match the user authorization.
 * Update it carefully.
 */
export enum WidgetTrigram {
  EAD = 'EAD',
  CEQ = 'CEQ',
  RGU = 'RGU',
  CET = 'CET',
  BAH = 'BAH',
  FIA = 'FIA',
  ETP = 'ETP',
  UAM = 'UAM',
  UCR = 'UCR', // TODO: remove it after the UAM release.
  LOA = 'LOA',
  MLP = 'MLP',
  CFT = 'CFT',
  SDA = 'SDA',
  CBO = 'CBO',
  CAW = 'CAW',
  MOB = 'MOB',
  SAP = 'SAP',
  SDL = 'SDL',
  CCG = 'CCG',
}

export interface UAMUserRole {
  role: string;
}

export class UAMUser {
  userId = '';
  firstName = '';
  lastName = '';
  myEuronextApps: WidgetTrigram[] = [];
  roles: UAMUserRole[] = [];
  personalDisclaimerAccepted?: string;
}

export class AppUser extends UAMUser {
  email = '';
  legacyRoles: string[] = [];
}

export interface LegacyRolesEntry {
  id: WidgetTrigram;
  roles: string;
}

export interface AccessToken {
  scope: string[];
}

export interface IdToken {
  email: string;
  'custom:uam'?: string;
  'custom:role'?: string;
}

export enum EnvId {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  QA = 'QA',
  QA2 = 'QA2',
  QAINT = 'QAINT',
  QAINT2 = 'QAINT2',
  QAINT4 = 'QAINT4',
  QAINT6 = 'QAINT6',
  TQA = 'TQA',
  EUATNR = 'EUATNR',
  PRODNR = 'PRODNR',
}

export class AppEnv {
  envId = EnvId.LOCAL;
  showEnvBar = true;
  showSettings = true;
  showCetRegulatoryDocs = true;
  userPoolId = '';
  clientId = '';
  mfaClientId = '';
  mfaScope = '';
  mfaEnabledApps: WidgetTrigram[] = [];
  uamEnabledApps: WidgetTrigram[] = [];
  oauthDomain = '';
  oauthScope: string[] = [];
  oauthSignOutRedirect = '';
  oauthIdentityProvider = '';
  oauthIdentityProviderDev?: string;
  oauthIdentityProviderEuat?: string;
  oauthIdentityProviderProd?: string;
  oauthMfaProvider = '';
  oauthMfaProviderDev?: string;
  oauthMfaProviderEuat?: string;
  oauthMfaProviderProd?: string;
  productFruitsCode?: string;
  uamApiUrl = '';
  uamDisclaimerEndpoint = '';
  ceqUrl = '';
  cetUrl = '';
  cboUrl = '';
  eadUrl = '';
  rguUrl = '';
  loaUrl = '';
  mlpUrl = '';
  uamUrl = '';
  uamCrUrl = ''; // TODO: remove it after the UAM release.
  cawUrl = '';
  etpUrl = '';
  sdaUrl = '';
  bahUrl = '';
  fiaUrl = '';
  sapUrl = '';
  sdlUrl = '';
  cftUrl = '';
  mobUrl = '';
  cepUrl = '';
  ccgUrl = '';
}

export interface AppConfig {
  host: string;
  getAccessToken: () => Promise<string>;
  getIdToken: () => Promise<string>;
}

export interface AppWindow extends Window {
  isDapPortal?: boolean;
}

export enum AppGateway {
  UAM = 'UAM',
}
