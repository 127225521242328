import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const SDLPage: FC = () => {
  const { sdlUrl } = useAppSelector(selectEnv);

  return (
    <MicroFrontend
      appId={WidgetTrigram.SDL}
      host={sdlUrl}
      filterPublicUrl={true}
    />
  );
};

export default SDLPage;
