import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const EADPage: FC = () => {
  const { eadUrl } = useAppSelector(selectEnv);

  const config = { Auth };

  return (
    <MicroFrontend
      appId={WidgetTrigram.EAD}
      host={eadUrl}
      name="ListingAdmission" // DEPRECATED: see MicroFrontend.tsx for more details
      Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
    />
  );
};

export default EADPage;
