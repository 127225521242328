import { Button, ErrorState, InformationIcon } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/config';

const AppGenericError: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <ErrorState
      icon={InformationIcon}
      title={t('errorBoundary.title')}
      description={t('errorBoundary.description')}
      actions={(
        <Button
          label={t('errorBoundary.buttonLabel')}
          onClick={() => navigate(ROUTES.HOME)}
        />
      )}
    />
  );
};

export default AppGenericError;
