import AppDisclaimer from 'components/AppDisclaimer/AppDisclaimer';
import CommonErrorState from 'components/CommonErrorState/CommonErrorState';
import { LayoutContainer, Loader, LoaderSize, useCover, VerticalFlex } from 'dap-ui-components';
import { checkDisclaimerSubmit } from 'helpers/authHelper';
import { getErrorMessage } from 'helpers/userHelper';
import { useTranslate } from 'i18n';
import { FC, useEffect, useState } from 'react';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';
import { WidgetTrigram } from '../../models';
import { useAssetsManifest, useMFA, useMounter } from './MicroFrontend.hooks';

export interface MicroFrontendProps {
  appId: WidgetTrigram;
  host: string;
  filterPublicUrl?: boolean;

  /**
   * @deprecated
   * Remove this prop and update your micro-fe app (index.tsx) setting the APP_NAME value matching the appId value.
   * Read the README "Migrate your app to the new dap-portal integration" section for the handling of this breaking change.
   */
  name?: string;

  /**
   * @deprecated
   * The MicroFrontend component pass automatically the needed configuration to the apps.
   * Remove this prop from your "pages/<app>" and update your micro-fe app (index.tsx) with the new passed config content.
   * Read the README "Migrate your app to the new dap-portal integration" section for the handling of this breaking change.
  */
  Config?: Record<string, any>;
}

const MicroFrontend: FC<MicroFrontendProps> = ({
  appId,
  host,
  filterPublicUrl,
  name,
  Config,
}) => {
  const t = useTranslate();
  const [isRedirecting, setRedirecting] = useState(false);
  const [isDisclaimerOpen, setDisclaimerOpen] = useState(false);
  const env = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);

  const loadAssets = useAssetsManifest(appId, host, filterPublicUrl);
  const { checkMFA, requestMFA } = useMFA(appId);
  const { containerId, mount, unmount } = useMounter(appId, host, name, Config);

  const { loading, reloadData, errorMessage } = useCover(async() => {
    const hasValidMFA = await checkMFA();
    const hasValidDisclaimer = !env.uamDisclaimerEndpoint || checkDisclaimerSubmit(user);

    if (!hasValidMFA) {
      requestMFA();
      setRedirecting(true);
    } else if (!hasValidDisclaimer) {
      setDisclaimerOpen(true);
    } else {
      await loadAssets();
      mount();
    }
  }, c => getErrorMessage(t, c));

  useEffect(() => {
    reloadData();
    return unmount;
  }, []);

  const showLoader = loading || isRedirecting;

  return (
    <>
      {showLoader && (
        <VerticalFlex
          $justify="center"
          $align="center"
          $grow
        >
          <Loader size={LoaderSize.V56} />
        </VerticalFlex>
      )}

      {isDisclaimerOpen && (
        <AppDisclaimer
          onClose={() => setDisclaimerOpen(false)}
          reloadData={reloadData}
        />
      )}

      <VerticalFlex
        id={containerId}
        $align="center"
        $justify="flex-start"
        $grow
      />

      {!showLoader && errorMessage && (
        <LayoutContainer>
          <CommonErrorState
            description={errorMessage}
            onReload={reloadData}
          />
        </LayoutContainer>
      )}
    </>
  );
};

export default MicroFrontend;
