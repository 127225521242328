import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const RGUPage: FC = () => {
  const { rguUrl } = useAppSelector(selectEnv);

  const config = { Auth };

  return (
    <>
      <MicroFrontend
        appId={WidgetTrigram.RGU}
        host={rguUrl}
        filterPublicUrl={true}
        name="RiskGuard" // DEPRECATED: see MicroFrontend.tsx for more details
        Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
      />

      <style>
        {`
        /* TODO: REMOVE THIS STYLES WHEN THE RGU APP WILL USE THE STANDARD PageLayoutStyles.Content */
        #RiskGuard-container > div {
          align-self: stretch;
        }
      `}
      </style>
    </>
  );
};

export default RGUPage;
