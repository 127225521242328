import { Auth } from '@aws-amplify/auth';
import { DISCLAIMER_SUBMIT, LEGACY_ROLES, MOCKED_ACCESS_TOKEN_KEY, MOCKED_ID_TOKEN_KEY } from 'config';
import jwtDecode from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import { AccessToken, AppEnv, AppUser, EnvId, IdToken, UAMUser, WidgetTrigram } from 'models';
import { clearMFAState } from './mfaHelper';
import { saveRedirect } from './redirectHelper';
import { getUserData } from './userHelper';

export const getMockedIdToken = (): string => {
  return localStorage.getItem(MOCKED_ID_TOKEN_KEY) || '';
};

export const getMockedAccessToken = (): string => {
  return localStorage.getItem(MOCKED_ACCESS_TOKEN_KEY) || '';
};

export const setMockedIdToken = (value: string): void => {
  localStorage.setItem(MOCKED_ID_TOKEN_KEY, value);
};

export const setMockedAccessToken = (value: string): void => {
  localStorage.setItem(MOCKED_ACCESS_TOKEN_KEY, value);
};

export const removeMockedTokens = (): void => {
  localStorage.removeItem(MOCKED_ACCESS_TOKEN_KEY);
  localStorage.removeItem(MOCKED_ID_TOKEN_KEY);
};

export const checkDisclaimerSubmit = (user: AppUser): boolean => {
  const value = localStorage.getItem(DISCLAIMER_SUBMIT) || '';
  const hasLocalSubmit = value.split(',').includes(user.email);
  const isLegacyUser = user.myEuronextApps.every(id => LEGACY_ROLES.some(r => r.id === id));

  return [
    hasLocalSubmit,
    !!user.personalDisclaimerAccepted,
    isLegacyUser,
  ].some(Boolean);
};

export const setDisclaimerSubmit = (email: string): void => {
  const value = localStorage.getItem(DISCLAIMER_SUBMIT);
  const list = value ? value.split(',').concat([email]).join(',') : email;
  localStorage.setItem(DISCLAIMER_SUBMIT, list);
};

export const removeDisclaimerSubmit = (): void => {
  localStorage.removeItem(DISCLAIMER_SUBMIT);
};

export const decodeToken = <T>(token: string): T | null => {
  try {
    return jwtDecode<T>(token);
  } catch (error) {
    return null;
  }
};

const getCognitoAccessToken = async(): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error) {
    return '';
  }
};

export const getAccessToken = async(): Promise<string> => {
  return getMockedAccessToken() || await getCognitoAccessToken();
};

export const getDecodedAccessToken = async(): Promise<AccessToken | null> => {
  const token = await getAccessToken();
  return decodeToken<AccessToken>(token);
};

const getCognitoIdToken = async(): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    return '';
  }
};

export const getIdToken = async(): Promise<string> => {
  return getMockedIdToken() || await getCognitoIdToken();
};

export const getDecodedIdToken = async(): Promise<IdToken | null> => {
  const token = await getIdToken();
  return decodeToken<IdToken>(token);
};

export const generateFakeIdToken = async(): Promise<string> => {
  // Get the original user data to generate the fake token
  const decodedIdToken = decodeToken(await getCognitoIdToken()) as IdToken;
  const { firstName, lastName, email } = getUserData(decodedIdToken);

  // Generate a new fake token enabling the access to all the widgets
  const customUAM: UAMUser = {
    userId: 'acbbbcc3-41e3-4620-806f-f697bdca99b1',
    firstName,
    lastName,
    myEuronextApps: Object.values(WidgetTrigram),
    roles: [
      { role: 'enx_ca_senior' },
      { role: 'enx_listing_senior' },
    ],
  };

  const customRole = LEGACY_ROLES
    .map(r => r.roles.replace(/\[|\]/g, ''))
    .join(',');

  const fakeIdToken = jwtEncode({
    'custom:uam': JSON.stringify(customUAM),
    'custom:role': `[${customRole}]`,
    email,
  }, '');

  return fakeIdToken;
};

export const isAuthenticated = async(): Promise<boolean> => {
  try {
    await Auth.currentSession();
    return true;
  } catch (error) {
    console.warn(`Authentication failed: ${error}`);
    return false;
  }
};

export const canShowWidget = (
  id: WidgetTrigram,
  user: AppUser,
  enabledUAMApps: WidgetTrigram[],
) => {
  const legacyRolesConfig = LEGACY_ROLES.find(r => r.id === id);
  const hasLegacyRoles = user.legacyRoles.some(r => r && legacyRolesConfig?.roles.includes(r.trim().toLowerCase()));
  const hasUAMCheck = enabledUAMApps && enabledUAMApps.includes(id);
  const hasUAMRoles = hasUAMCheck && user.myEuronextApps.includes(id);
  return hasLegacyRoles || hasUAMRoles;
};

// Get the identity provider checking if there is a configured override.
// It handle the business need to allow the prod users to access also in EUAT env with the PROD credentials.
// Two different links are displayed in the prod website (one of it with the pEnv parameter).
export const getIdentityProvider = (env: AppEnv, isMfa: boolean): string => {
  // Get the env override value from the querystring parameter
  const urlParams = new URLSearchParams(window.location.search);
  const queryEnvId = urlParams.get('penv')?.toUpperCase();
  const envId = queryEnvId || window.sessionStorage.getItem('penv');

  // The penv is stored in the session to preserve it during the MFA handshake (redirects)
  if (envId) {
    window.sessionStorage.setItem('penv', envId);
  }

  const envMap = new Map<string, string | undefined>([
    // standard providers
    ['standard-default', env.oauthIdentityProvider],
    [`standard-${EnvId.DEV}`, env.oauthIdentityProviderDev],
    [`standard-${EnvId.EUATNR}`, env.oauthIdentityProviderEuat],
    [`standard-${EnvId.PRODNR}`, env.oauthIdentityProviderProd],

    // MFA providers
    ['mfa-default', env.oauthMfaProvider],
    [`mfa-${EnvId.DEV}`, env.oauthMfaProviderDev],
    [`mfa-${EnvId.EUATNR}`, env.oauthMfaProviderEuat],
    [`mfa-${EnvId.PRODNR}`, env.oauthMfaProviderProd],
  ]);

  const type = isMfa ? 'mfa' : 'standard';
  const envProvider = envMap.get(`${type}-${envId}`);
  const fallbackProvider = envMap.get(`${type}-default`) as string;

  return envProvider || fallbackProvider;
};

export const requestSignIn = async(env: AppEnv): Promise<void> => {
  const customProvider = getIdentityProvider(env, false);
  console.info(`Sign-in in progress... (${customProvider})`);
  clearMFAState(env);
  removeDisclaimerSubmit();
  removeMockedTokens();
  saveRedirect();
  Auth.federatedSignIn({ customProvider });
};
