import { Auth } from '@aws-amplify/auth';
import { MFA_CLIENT_ENABLED_KEY, MFA_RETRY_COUNT, MFA_RETRY_COUNT_KEY } from 'config';
import { AccessToken, AppEnv } from 'models';

export const getMFARetryCount = (): number => {
  return Number(sessionStorage.getItem(MFA_RETRY_COUNT_KEY));
};

export const incrementMFARetryCount = (): void => {
  sessionStorage.setItem(MFA_RETRY_COUNT_KEY, (getMFARetryCount() + 1).toString());
};

export const removeMFARetryCount = (): void => {
  sessionStorage.removeItem(MFA_RETRY_COUNT_KEY);
};

// Check the redirects count to prevent an infinite loop (e.g. after a failed auth)
export const hasMFARetryExceeded = (): boolean => {
  const check = getMFARetryCount() >= MFA_RETRY_COUNT;

  if (check) {
    console.warn('MFA max redirects reached.');
    removeMFARetryCount();
  }

  return check;
};

// Check if the app should use the MFA client id (the localStorage is used to persist the state on multiple browser tabs)
export const hasMFAClientEnabled = (): boolean => {
  return localStorage.getItem(MFA_CLIENT_ENABLED_KEY) !== null;
};

// Set the status of the MFA client
export const setMFAClientEnabled = (isEnabled: boolean): void => {
  isEnabled ? localStorage.setItem(MFA_CLIENT_ENABLED_KEY, 'applied') : localStorage.removeItem(MFA_CLIENT_ENABLED_KEY);
};

// Function used by MicroFrontend.tsx to check if the MFA process should be verified and its result
export const checkMFAScope = (accessToken: AccessToken | null, mfaScope: string): boolean => {
  // If the MFA scope was found, the user is already authenticated with MFA
  const hasMFAScope = accessToken?.scope.includes(mfaScope) || false;
  console.info('MFA check.', 'Result:', hasMFAScope, 'Scope:', accessToken?.scope);

  // Clear the retry counter
  if (hasMFAScope) {
    removeMFARetryCount();
  }

  return hasMFAScope;
};

export const setupAuth = (env: AppEnv, withMFA: boolean): void => {
  const userPoolWebClientId = withMFA ? env.mfaClientId : env.clientId;
  const scope = withMFA ? env.oauthScope.concat(env.mfaScope) : env.oauthScope;

  const authConfig = {
    mandatorySignIn: true,
    region: 'eu-west-1',
    userPoolId: env.userPoolId,
    userPoolWebClientId,
    oauth: {
      domain: env.oauthDomain,
      scope,
      redirectSignIn: window.location.origin,
      redirectSignOut: env.oauthSignOutRedirect,
      responseType: 'code',
    },
  };

  Auth.configure(authConfig);
};

// Function used by App.tsx to request a new MFA login to the external provider
export const requestMFASignIn = (env: AppEnv, customProvider: string): void => {
  // Add the mfa client id and scope to the amplify configuration to call the custom provider
  setupAuth(env, true);

  // Persist the use of the MFA client id
  setMFAClientEnabled(true);

  // Increment the retry counter to track the redirects
  incrementMFARetryCount();

  // Run the redirect to the MFA login
  Auth.federatedSignIn({ customProvider });
};

export const clearMFAState = (env: AppEnv): void => {
  // Remove the stored state that force the MFA client id
  setMFAClientEnabled(false);

  // Reset the config to the initial state
  setupAuth(env, false);
};
