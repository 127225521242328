import AppGenericError from 'components/AppGenericError/AppGenericError';
import { BannerProvider, BannerProviderProps, ErrorBoundary, ErrorBoundaryProps, ToastProvider, ToastProviderProps } from 'dap-ui-components';
import i18n from 'i18n';
import { ComponentClass, FC, ReactElement } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';

// Export the component that merge all the providers
const AppProviders: FC<{ children: ReactElement }> = ({ children }) => {
  // List of all the app providers
  const providers: { provider: unknown, props?: Record<string, any> }[] = [
    { provider: BrowserRouter },
    { provider: ReduxProvider, props: { store } },
    { provider: ErrorBoundary, props: { fallback: AppGenericError } as ErrorBoundaryProps },
    { provider: ToastProvider, props: { closeTitle: i18n.t('common.close') } as ToastProviderProps },
    { provider: BannerProvider, props: { closeTitle: i18n.t('common.close') } as BannerProviderProps },
  ];

  return providers.reduceRight((accumulator, item) => {
    const AppProvider = item.provider as ComponentClass;
    return <AppProvider {...item.props}>{accumulator}</AppProvider>;
  }, children);
};

export default AppProviders;
