import { getAccessToken, getDecodedAccessToken, getIdentityProvider, getIdToken, getMockedAccessToken, requestSignIn } from 'helpers/authHelper';
import { checkMFAScope, requestMFASignIn } from 'helpers/mfaHelper';
import { saveRedirect } from 'helpers/redirectHelper';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';
import { AppConfig, WidgetTrigram } from '../../models';

export const useAssetsManifest = (
  appId: WidgetTrigram,
  host: string,
  filterPublicUrl?: boolean,
) => {
  const appendAssetFile = (sourcePath: string, key: string) => new Promise<void>((resolve, reject) => {
    // Remove the PUBLIC_URL from the target manifest files, it it exists
    // The PUBLIC_URL is present in the target manifest to support the test of the target app as a standalone SPA
    const projectFolder = host.substring(host.lastIndexOf('/'));
    if (filterPublicUrl && sourcePath.indexOf(projectFolder) === 0) {
      sourcePath = sourcePath.replace(projectFolder, '');
    }

    const script = document.createElement('script');
    script.id = key;
    script.crossOrigin = '';
    script.onload = () => resolve();
    script.onerror = reject;
    script.src = `${host}${sourcePath}`;

    document.body.appendChild(script);
  });

  const loadAssets = async() => {
    // Load the files only if the child app is not already mounted
    if (!document.getElementById(`${appId}-main.js`)) {
      const response = await fetch(`${host}/asset-manifest.json?_=${Date.now()}`);
      const manifest = await response.json();

      const assetsQueue = Object.keys(manifest.files)
        .filter(key => key.endsWith('.js'))
        .map(key => appendAssetFile(manifest.files[key], `${appId}-${key}`));

      await Promise.all(assetsQueue);
    }
  };

  return loadAssets;
};

export const useMFA = (appId: WidgetTrigram) => {
  const env = useAppSelector(selectEnv);

  const checkMFA = async(): Promise<boolean> => {
    const hasMFAEnabled = env.mfaEnabledApps && env.mfaEnabledApps.includes(appId);
    const decodedAccessToken = await getDecodedAccessToken();
    const hasMFAScope = checkMFAScope(decodedAccessToken, env.mfaScope);
    return !hasMFAEnabled || hasMFAScope;
  };

  const requestMFA = (): void => {
    saveRedirect();
    const customProvider = getIdentityProvider(env, true);
    console.info(`MFA in progress... (${customProvider})`);
    requestMFASignIn(env, customProvider);
  };

  return {
    checkMFA,
    requestMFA,
  };
};

export const useMounter = (
  appId: WidgetTrigram,
  host: string,
  name?: string,
  Config?: Record<string, any>,
) => {
  const env = useAppSelector(selectEnv);
  const containerId = `${name || appId}-container`;

  const checkExpiredToken = (token: string): string => {
    // Trigger a new login when the token is expired
    if (!token) {
      requestSignIn(env);
    }

    return token;
  };

  const mount = () => {
    const legacyRender = (window as any)[`render${name}`];
    const newRender = (window as any)[`render${appId}`];
    const render = legacyRender || newRender;

    const integration: AppConfig = {
      ...Config, // TODO: this is passed for backward compatibility, remove it when all the apps will use the standard integration
      host,
      getAccessToken: () => getAccessToken().then(checkExpiredToken),
      getIdToken: () => getIdToken().then(checkExpiredToken),
    };

    if (Config) {
      // TODO: Legacy integration, remove it when all the apps will use the standard integration
      // NOTE: The mocked access token is passed twice because some apps expect it as 3rd parameter and other ones as 4th.
      render(containerId, integration, getMockedAccessToken(), getMockedAccessToken());
    } else {
      // Standard integration
      render(containerId, integration);
    }
  };

  const unmount = () => {
    const legacyUnmount = (window as any)[`unmount${name}`];
    const newUnmount = (window as any)[`unmount${appId}`];
    const unmountFunction = legacyUnmount || newUnmount;
    unmountFunction?.(containerId);
  };

  return {
    containerId,
    mount,
    unmount,
  };
};
