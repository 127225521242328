import { BoldTextM, HeadingL, LayoutContainer, Link, Paper, PaperBody, PaperHeader, Space, VerticalFlex } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';
import { TermsConditionsList } from './TermsConditions.style';

const TermsConditions: FC = () => {
  const t = useTranslate();

  return (
    <LayoutContainer>
      <Paper>
        <PaperHeader>
          <HeadingL>
            {t('conditions.title')}
          </HeadingL>
        </PaperHeader>

        <PaperBody>
          <VerticalFlex $gap={Space.V40}>
            <TermsConditionsList>
              <li>{t('conditions.firstSection.text1')}</li>

              <li>
                {t('conditions.firstSection.text2')}<br />
                {t('conditions.firstSection.text3')}
              </li>
            </TermsConditionsList>

            <VerticalFlex $gap={Space.V16}>
              <div>{t('conditions.preamble.title')}</div>
              <div>{t('conditions.preamble.text1')}</div>
              <div>{t('conditions.preamble.text2')}</div>
              <div>{t('conditions.preamble.text3')}</div>
              <div>{t('conditions.preamble.text4')}</div>
            </VerticalFlex>

            <VerticalFlex $gap={Space.V16}>
              <div>{t('conditions.terms.title')}</div>

              <TermsConditionsList>
                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.0.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.0.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.1.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.1.texts.0')}</div>
                    <div>{t('conditions.terms.list.1.texts.1')}</div>
                    <div>{t('conditions.terms.list.1.texts.2')}</div>
                    <div>{t('conditions.terms.list.1.texts.3')}</div>
                    <div>{t('conditions.terms.list.1.texts.4')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.2.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.2.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.3.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.3.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.4.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.4.texts.0')}</div>
                    <div>{t('conditions.terms.list.4.texts.1')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.5.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.5.texts.0')}</div>
                    <div>{t('conditions.terms.list.5.texts.1')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.6.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.6.texts.0')}</div>
                    <div>{t('conditions.terms.list.6.texts.1')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.7.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.7.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.8.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.8.texts.0')}</div>
                    <div>{t('conditions.terms.list.8.texts.1')}</div>
                    <div>{t('conditions.terms.list.8.texts.2')}</div>
                    <div>{t('conditions.terms.list.8.texts.3')}</div>
                    <div>{t('conditions.terms.list.8.texts.4')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.9.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.9.texts.0')}</div>
                    <div>{t('conditions.terms.list.9.texts.1')}</div>

                    <Link
                      label="https://www.euronext.com/privacy-statement"
                      href="https://www.euronext.com/privacy-statement"
                      blank
                    />

                    <div>{t('conditions.terms.list.9.texts.2')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.10.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.10.texts.0')}</div>
                    <div>{t('conditions.terms.list.10.texts.1')}</div>
                    <div>{t('conditions.terms.list.10.texts.2')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.11.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.11.texts.0')}</div>
                    <div>{t('conditions.terms.list.11.texts.1')}</div>
                    <div>{t('conditions.terms.list.11.texts.2')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.12.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.12.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.13.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.13.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.14.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.14.texts.0')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.15.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.15.texts.0')}</div>
                    <div>{t('conditions.terms.list.15.texts.1')}</div>
                    <div>{t('conditions.terms.list.15.texts.2')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.16.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.16.texts.0')}</div>
                    <div>{t('conditions.terms.list.16.texts.1')}</div>
                    <div>{t('conditions.terms.list.16.texts.2')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.17.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.17.texts.0')}</div>

                    <Link
                      label="https://myeuronext.prod.euronext.cloud"
                      href="https://myeuronext.prod.euronext.cloud/"
                      blank
                    />
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.18.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.18.texts.0')}</div>
                    <div>{t('conditions.terms.list.18.texts.1')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.19.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.19.texts.0')}</div>
                    <div>{t('conditions.terms.list.19.texts.1')}</div>
                  </VerticalFlex>
                </li>

                <li>
                  <VerticalFlex $gap={Space.V8}>
                    <BoldTextM>{t('conditions.terms.list.20.name')}</BoldTextM>
                    <div>{t('conditions.terms.list.20.texts.0')}</div>
                    <div>{t('conditions.terms.list.20.texts.1')}</div>
                  </VerticalFlex>
                </li>
              </TermsConditionsList>
            </VerticalFlex>

            <VerticalFlex $gap={Space.V16}>
              <div>{t('conditions.security.title')}</div>

              <TermsConditionsList>
                <li>{t('conditions.security.list.0')}</li>
                <li>{t('conditions.security.list.1')}</li>
                <li>{t('conditions.security.list.2')}</li>
                <li>{t('conditions.security.list.3')}</li>
              </TermsConditionsList>

              <VerticalFlex $gap={Space.V24}>
                <VerticalFlex $gap={Space.V8}>
                  <div>{t('conditions.security.confidential.0')}</div>
                  <div>{t('conditions.security.confidential.1')}</div>
                </VerticalFlex>

                <VerticalFlex $gap={Space.V8}>
                  <div>{t('conditions.security.confidential.2')}</div>

                  <Link
                    label="ckantaridis@euronext.com"
                    href="mailto:ckantaridis@euronext.com"
                  />

                  <Link
                    label="+33 6 3094 5302"
                    href="tel:+33630945302"
                  />
                </VerticalFlex>

                <VerticalFlex $gap={Space.V8}>
                  <div>{t('conditions.security.confidential.3')}</div>

                  <Link
                    label="poponchel@euronext.com"
                    href="mailto:poponchel@euronext.com"
                  />

                  <Link
                    label="+33 6 3127 1075"
                    href="tel:+33631271075"
                  />
                </VerticalFlex>
              </VerticalFlex>
            </VerticalFlex>

            <VerticalFlex $gap={Space.V8}>
              <div>{t('conditions.services.0')}</div>
              <div>{t('conditions.services.1')}</div>
              <div>{t('conditions.services.2')}</div>

              <Link
                label="https://connect2.euronext.com/en/trading-services/riskguard"
                href="https://connect2.euronext.com/en/trading-services/riskguard"
                blank
              />
            </VerticalFlex>
          </VerticalFlex>
        </PaperBody>
      </Paper>
    </LayoutContainer>
  );
};

export default TermsConditions;
