import StockIcon from 'components/StockIcon/StockIcon';
import { HeadingM } from 'dap-ui-components';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BodySection, WidgetLayout } from './Widget.style';

interface WidgetProps {
  title: string;
  route: string;
}

const Widget: FC<WidgetProps> = ({
  title,
  route,
}) => {
  return (
    <Link to={route}>
      <WidgetLayout>
        <HeadingM>
          {title}
        </HeadingM>

        <BodySection>
          <StockIcon />
        </BodySection>
      </WidgetLayout>
    </Link>
  );
};

export default Widget;
