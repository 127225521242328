import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serializable } from 'dap-ui-components';
import { AppEnv, AppUser } from 'models';
import { RootState } from '../types';

export interface CoreState {
  env: AppEnv;
  user: AppUser;
}

export const initialState: CoreState = {
  env: serializable(new AppEnv()),
  user: serializable(new AppUser()),
};

const slice = createSlice({
  name: 'core',

  initialState,

  reducers: {
    updateEnv: (state, action: PayloadAction<AppEnv>) => {
      state.env = action.payload;
    },

    updateUser: (state, action: PayloadAction<AppUser>) => {
      state.user = action.payload;
    },
  },
});

export const {
  updateEnv,
  updateUser,
} = slice.actions;

export const selectEnv = (store: RootState) => store.core.env;
export const selectUser = (store: RootState) => store.core.user;

export const core = slice.reducer;
