import { BoldTextS, BorderRadius, Color, Space } from 'dap-ui-components';
import styled from 'styled-components';

export const SubWidgetRoot = styled.div`
  align-items: stretch;
  background: ${Color.Neutral.V30};
  border-radius: ${BorderRadius.V8};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: ${Color.Teal.V30};
  display: flex;
  flex-direction: column;
  height: 252px;
  justify-content: space-between;
  padding: ${Space.V24};
`;

export const SubWidgetBody = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const SubWidgetLinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Space.V24};
  width: 100%;
`;

export const SubWidgetLinkCounter = styled(BoldTextS)`
  background: ${Color.Neutral.V00};
  border-radius: ${BorderRadius.V50};
  color: ${Color.Neutral.V70};
  padding: ${Space.V4} ${Space.V8};
`;
