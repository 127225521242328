import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const BAHPage: FC = () => {
  const { bahUrl } = useAppSelector(selectEnv);

  return (
    <MicroFrontend
      appId={WidgetTrigram.BAH}
      host={bahUrl}
      filterPublicUrl={true}
    />
  );
};

export default BAHPage;
