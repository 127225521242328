import { Space } from 'dap-ui-components';
import styled from 'styled-components';

export const TermsOfUseList = styled.ol`
  counter-reset: item;

  li {
    margin-bottom: ${Space.V16};
    
    :before {
      content: counters(item, ".") " ";
      counter-increment: item;
      float: left;
      margin-right: ${Space.V12};
    }
  }
`;
