import AppFooter from 'components/AppFooter/AppFooter';
import AppTopBar from 'components/AppTopBar/AppTopBar';
import { FC } from 'react';
import { ProductFruits } from 'react-product-fruits';
import AppRoutes from 'routes/AppRoutes';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const AppContent: FC = () => {
  const env = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);

  return (
    <>
      <AppTopBar />

      <AppRoutes />

      {user.userId && env.productFruitsCode && (
        <ProductFruits
          workspaceCode={env.productFruitsCode}
          language="en"
          debug={false}
          user={{
            username: user.userId,
            props: {
              roles: user.roles.map(r => r.role),
            },
          }}
        />
      )}

      <AppFooter />
    </>
  );
};

export default AppContent;
