import { LegacyRolesEntry, WidgetTrigram } from 'models';

// Roles config used by the legacy auth check.
export const LEGACY_ROLES: LegacyRolesEntry[] = [
  {
    id: WidgetTrigram.EAD,
    roles: '[da_la_external,da_la_euronext,da_la_admin,listing_sponsor]',
  },
  {
    id: WidgetTrigram.RGU,
    roles: '[da_riskguard,mca]',
  },
];

// Mocked tokens setup.
export const MOCKED_ACCESS_TOKEN_KEY = 'MOCKED_ACCESS_TOKEN';
export const MOCKED_ID_TOKEN_KEY = 'MOCKED_ID_TOKEN';

// User personal disclaimer.
export const DISCLAIMER_SUBMIT = 'DISCLAIMER_SUBMIT';

// MFA setup.
export const MFA_RETRY_COUNT = 3;
export const MFA_RETRY_COUNT_KEY = 'MFA_RETRY_COUNT';
export const MFA_REQUEST_KEY = 'MFA_REQUEST';
export const MFA_CLIENT_ENABLED_KEY = 'MFA_CLIENT_ID';
