import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CAWPage: FC = () => {
  const { cawUrl } = useAppSelector(selectEnv);

  return (
    <MicroFrontend
      appId={WidgetTrigram.CAW}
      host={cawUrl}
      filterPublicUrl={true}
    />
  );
};

export default CAWPage;
