import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const MLPPage: FC = () => {
  const { mlpUrl } = useAppSelector(selectEnv);

  const config = { Auth };

  return (
    <MicroFrontend
      appId={WidgetTrigram.MLP}
      host={mlpUrl}
      filterPublicUrl
      name="MMLP" // DEPRECATED: see MicroFrontend.tsx for more details
      Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
    />
  );
};

export default MLPPage;
