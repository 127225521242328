import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CEPPage: FC = () => {
  const { cepUrl } = useAppSelector(selectEnv);

  return (
    <MicroFrontend
      appId={'CEP' as WidgetTrigram} // TODO: replace it with the original "CET" and sync the app
      host={cepUrl}
      filterPublicUrl={true}
    />
  );
};

export default CEPPage;
