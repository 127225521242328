const redirectPathKey = 'REDIRECT_PATH';

export const getRedirect = () => {
  return sessionStorage.getItem(redirectPathKey);
};

export const saveRedirect = () => {
  sessionStorage.setItem(redirectPathKey, window.location.pathname);
};

export const removeRedirect = () => {
  sessionStorage.removeItem(redirectPathKey);
};
