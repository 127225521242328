import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import en from './en.json';

const resources = {
  en: { translation: en },
};

export const languageList = Object.keys(resources);

const detection = {
  order: ['cookie', 'navigator'],
  caches: ['cookie'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection,
    resources,
    fallbackLng: 'en',
    supportedLngs: languageList,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

// Hook to reduce the code verbosity
type InterpolationMap = Record<string, string | number | null | undefined>;
export type TranslateFunction = (id: string, defaultMessage?: string | null, values?: InterpolationMap) => string;

export function useTranslate(): TranslateFunction {
  const { t } = useTranslation();
  return (id, defaultMessage, values) => t(id, defaultMessage || '', values);
}
