import { Color } from 'dap-ui-components';
import { FC } from 'react';

const StockIcon: FC = () => {
  return (
    <svg
      viewBox="0 0 101 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={80}
    >
      <path
        fill={Color.Teal.V30}
        stroke={Color.Teal.V30}
        d="
          M48.5873 11.4171L49.173 12.0029H50.0015H90.0015C92.1232 12.0029 94.158
          12.8458 95.6583 14.3461C97.1586 15.8464 98.0015 17.8812 98.0015 20.0029V70.0029C98.0015
          72.1246 97.1586 74.1595 95.6583 75.6598C94.158 77.1601 92.1232 78.0029 90.0015
          78.0029H10.0015C7.87974 78.0029 5.8449 77.1601 4.34461 75.6598C2.84432 74.1595 2.00146 72.1246
          2.00146 70.0029V10.0029C2.00146 5.5575 5.55603 2.00293 10.0015 2.00293H39.173L48.5873 11.4171ZM50.0015
          23.0029H48.0015V25.0029V35.0029V37.0029H50.0015H65.0015H67.0015V35.0029V25.0029V23.0029H65.0015H50.0015ZM70.0015
          23.0029H68.0015V25.0029V35.0029V37.0029H70.0015H85.0015H87.0015V35.0029V25.0029V23.0029H85.0015H70.0015ZM50.0015
          38.0029H48.0015V40.0029V50.0029V52.0029H50.0015H65.0015H67.0015V50.0029V40.0029V38.0029H65.0015H50.0015ZM70.0015
          38.0029H68.0015V40.0029V50.0029V52.0029H70.0015H85.0015H87.0015V50.0029V40.0029V38.0029H85.0015H70.0015ZM50.0015
          53.0029H48.0015V55.0029V65.0029V67.0029H50.0015H65.0015H67.0015V65.0029V55.0029V53.0029H65.0015H50.0015ZM70.0015
          53.0029H68.0015V55.0029V65.0029V67.0029H70.0015H85.0015H87.0015V65.0029V55.0029V53.0029H85.0015H70.0015Z
        "
        strokeWidth="4"
      />
    </svg>
  );
};

export default StockIcon;
