import { configureStore } from '@reduxjs/toolkit';
import { core } from './core/slice';

const store = configureStore({
  reducer: {
    core,
  },
});

export default store;
