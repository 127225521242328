import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { getMockedAccessToken } from 'helpers/authHelper';
import { WidgetTrigram } from 'models';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CBOPage = () => {
  const { cboUrl } = useAppSelector(selectEnv);

  const config = {
    Auth,
    host: cboUrl,
    devToken: getMockedAccessToken(),
  };

  return (
    <MicroFrontend
      appId={WidgetTrigram.CBO}
      host={config.host}
      filterPublicUrl={true}
    />
  );
};

export default CBOPage;
