import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { getMockedAccessToken } from 'helpers/authHelper';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CFTPage: FC = () => {
  const { cftUrl } = useAppSelector(selectEnv);

  const config = {
    Auth,
    host: cftUrl,
    devToken: getMockedAccessToken(),
  };

  return (
    <MicroFrontend
      appId={WidgetTrigram.CFT}
      host={config.host}
      filterPublicUrl={true}
      name="CFTS" // DEPRECATED: see MicroFrontend.tsx for more details
      Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
    />
  );
};

export default CFTPage;
