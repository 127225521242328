import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const MOBPage: FC = () => {
  const { mobUrl } = useAppSelector(selectEnv);

  return (
    <MicroFrontend
      appId={WidgetTrigram.MOB}
      host={mobUrl}
      filterPublicUrl
    />
  );
};

export default MOBPage;
