import { Breakpoint, Button, ErrorState, HorizontalFlex, InformationIcon } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';

export interface CommonErrorStateProps {
  description: string;
  onReload: () => void;
}

const CommonErrorState: FC<CommonErrorStateProps> = ({
  description,
  onReload,
}) => {
  const t = useTranslate();

  return (
    <ErrorState
      icon={InformationIcon}
      title={t('errorBoundary.title')}
      description={description}
      actions={(
        <HorizontalFlex
          $justify="center"
          $breakpoint={Breakpoint.S}
        >
          <Button
            label={t('common.refreshButton')}
            onClick={onReload}
          />
        </HorizontalFlex>
      )}
    />
  );
};

export default CommonErrorState;
