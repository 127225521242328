import { Auth } from '@aws-amplify/auth';
import MicroFrontend from 'components/MicroFrontend/MicroFrontend';
import { WidgetTrigram } from 'models';
import { FC } from 'react';
import { selectEnv } from 'store/core/slice';
import { useAppSelector } from 'store/types';

const CEQPage: FC = () => {
  const { ceqUrl } = useAppSelector(selectEnv);

  const config = { Auth };

  return (
    <MicroFrontend
      appId={WidgetTrigram.CEQ}
      host={ceqUrl}
      filterPublicUrl={true}
      name="CorporateActionEquities" // DEPRECATED: see MicroFrontend.tsx for more details
      Config={config} // DEPRECATED: see MicroFrontend.tsx for more details
    />
  );
};

export default CEQPage;
