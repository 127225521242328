import { BoldTextM, Link } from 'dap-ui-components';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubWidgetBody, SubWidgetLinkContainer, SubWidgetLinkCounter, SubWidgetRoot } from './SubWidget.style';

export interface SubWidgetLinkModel {
  title: string;
  route: string;
  counter?: number;
}

export interface SubWidgetProps {
  title: string;
  links: SubWidgetLinkModel[];
  footer?: ReactNode;
  qa?: string;
}

const SubWidget: FC<SubWidgetProps> = ({
  title,
  links,
  footer,
  qa,
}) => {
  const navigate = useNavigate();

  return (
    <SubWidgetRoot>
      <BoldTextM data-qa={`${qa}-title`}>
        {title}
      </BoldTextM>

      <SubWidgetBody>
        {links.map((link, index) => (
          <SubWidgetLinkContainer key={link.route}>
            <Link
              qa={`${qa}-link-${index + 1}`}
              label={link.title}
              onClick={() => navigate(link.route)}
            />

            {link.counter !== undefined && (
              <SubWidgetLinkCounter>
                {link.counter}
              </SubWidgetLinkCounter>
            )}
          </SubWidgetLinkContainer>
        ))}
      </SubWidgetBody>

      {footer && <section>{footer}</section>}
    </SubWidgetRoot>
  );
};

export default SubWidget;
