import { Space } from 'dap-ui-components';
import styled from 'styled-components';

export const TermsConditionsList = styled.ol`
  list-style: decimal;

  li {
    margin-bottom: ${Space.V16};
  }
`;
